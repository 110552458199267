@charset 'UTF-8';
@import "/Users/fernandosouza/sites/onca-nagoya-patent/src/scss/utility/_media-queries.scss";
@import "/Users/fernandosouza/sites/onca-nagoya-patent/src/scss/utility/_mixin.scss";


#page404 {
  .taC { display: block; font-size: 18px; line-height: 30px; letter-spacing: 2px; text-align: center; margin: 20px 0 0 0;
    a { font-size: 18px;text-decoration: none; color: #00B0FF;  }
  }
  .page-title { font-size: 32px; font-weight: bold; letter-spacing: 2px; padding-bottom: 8px; text-align: center;}
}
.page_404 { padding: 200px 20px 200px; }
.l-en {
  .taC {
    letter-spacing: 0;
  }
  .page-title { letter-spacing: 1px; }
}